document.addEventListener("DOMContentLoaded", () => {
  const imgs = document.querySelectorAll("img")

  imgs.forEach((img) => {
    if (!img.hasAttribute("alt")) {
      const title = document.querySelector("title")
      img.setAttribute("alt", title ? title.textContent : "")
    }
  })
})
